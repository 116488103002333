import { useTheme } from '@material-ui/core';
import { FC } from 'react';
import { iconFillColor } from 'theme';
import { DefaultIconType } from './types';

export const SplitCGuideIcon: FC<DefaultIconType> = ({
  active = false,
  width = 24,
  height = 24,
}) => {
  const { splitcPalette, palette } = useTheme();
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.775 20.5374C10.8917 20.5624 11.0167 20.575 11.15 20.575C11.2834 20.575 11.4084 20.5624 11.525 20.5374C11.6417 20.5125 11.7584 20.4666 11.875 20.4L17.625 17.25C17.8584 17.1166 18.0459 16.9333 18.1875 16.7C18.3292 16.4666 18.4 16.2083 18.4 15.925V10.825L16.9 11.725V15.925L11.15 19.0999L5.40002 15.925V11.675L3.90002 10.825V15.925C3.90002 16.2083 3.97086 16.4666 4.11252 16.7C4.25419 16.9333 4.44169 17.1166 4.67502 17.25L10.425 20.4C10.5417 20.4666 10.6584 20.5125 10.775 20.5374Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.375 9.45C1.24167 9.38333 1.14583 9.29167 1.0875 9.175C1.02917 9.05833 1 8.93333 1 8.8C1 8.66667 1.02917 8.54167 1.0875 8.425C1.14583 8.30833 1.24167 8.21667 1.375 8.15L10.425 3.2C10.5417 3.13333 10.6583 3.08333 10.775 3.05C10.8917 3.01667 11.0167 3 11.15 3C11.2833 3 11.4083 3.01667 11.525 3.05C11.6417 3.08333 11.7583 3.13333 11.875 3.2L21.775 8.575C21.9083 8.65833 22.0083 8.75833 22.075 8.875C22.1417 8.99167 22.175 9.11667 22.175 9.25V15.975C22.175 16.1917 22.1042 16.3708 21.9625 16.5125C21.8208 16.6542 21.6417 16.725 21.425 16.725C21.2083 16.725 21.0292 16.6542 20.8875 16.5125C20.7458 16.3708 20.675 16.1917 20.675 15.975V9.675L18.4 10.825L16.9 11.725L11.875 14.425C11.7583 14.4917 11.6417 14.5375 11.525 14.5625C11.4083 14.5875 11.2833 14.6 11.15 14.6C11.0167 14.6 10.8958 14.5875 10.7875 14.5625C10.6792 14.5375 10.5667 14.4917 10.45 14.425L5.4 11.675L3.9 10.825L1.375 9.45ZM11.15 13.025L19.025 8.725L11.15 4.5L3.32501 8.725L11.15 13.025Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
    </svg>
  );
};
