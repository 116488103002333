import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

if (import.meta.env.VITE_SENTRY_DNS) {
  Sentry.init({
    dsn: String(import.meta.env.VITE_SENTRY_DNS),
    release: String(import.meta.env.VITE_COMMIT || import.meta.env.NODE_ENV),
    autoSessionTracking: false,
  });
}

const root = createRoot(document.getElementById('root')!);

root.render(<App />);
