import { useTheme } from '@material-ui/core';
import { FC } from 'react';
import { iconBorderColor, iconFillColor } from 'theme';
import { DefaultIconType } from './types';

export const ComissionPlanIcon: FC<DefaultIconType> = ({
  width = 24,
  height = 24,
  active = false,
}) => {
  const { palette, splitcPalette } = useTheme();
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Zm-7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM7 7h10V5h2v14H5V5h2v2Z"
        fill={iconBorderColor(splitcPalette, active)}
      />
      <path
        d="m9 12-1.5 1.5L11 17l6-6-1.5-1.5L11 14l-2-2Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
    </svg>
  );
};
