import { useTheme } from '@material-ui/core';
import { FC } from 'react';
import { iconBorderColor, iconFillColor } from 'theme';
import { DefaultIconType } from './types';

export const GraphIcon: FC<DefaultIconType> = ({ width = 24, height = 24, active = false }) => {
  const { splitcPalette, palette } = useTheme();
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 20h-1V5a1 1 0 0 0-2 0v15h-2v-7a1 1 0 0 0-2 0v7h-2V9a1 1 0 0 0-2 0v11H8v-3a1 1 0 1 0-2 0v3H4V3a1 1 0 0 0-2 0v18a1 1 0 0 0 1 1h18a1 1 0 0 0 0-2Z"
        fill={iconBorderColor(splitcPalette, active)}
      />
      <path d="M18 5a1 1 0 1 1 2 0v15h-2V5Z" fill={iconFillColor(palette, splitcPalette, active)} />
    </svg>
  );
};
