import { Avatar, Box, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/ExitToAppOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { LogoSplitc } from 'assets/icons';
import i18next from 'i18next';
import useSelector from 'modules/default-selector';
import history from 'pages/history';
import ROUTES from 'pages/routes-enum';
import { MouseEvent, useState } from 'react';
import { UsersClaims } from 'shared-types';
import { useGetClaims } from 'utils/claim';
import * as S from './styles';

const Footer = ({ isOpened }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const handleClose = () => setAnchorEl(null);
  const user = useSelector(state => state.user.credor?.nome_credor);
  const externalCreditorId = useSelector(state => state.user.credor?.id_credor_externo);

  const { findAndUseClaim } = useGetClaims();

  const { data: hideLogo } = findAndUseClaim(UsersClaims.HIDE_SPLITC_LOGO);
  const shouldHideLogo = hideLogo !== undefined ? hideLogo : false;

  const { data: hideLogout } = findAndUseClaim(UsersClaims.HIDE_LOGOUT);
  const shouldHideLogout = hideLogout !== undefined ? hideLogout : false;

  const handleClick = (event: MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);
  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <ListItem button aria-controls="customized-menu" aria-haspopup="true" onClick={handleClick}>
        <S.WrapperUser>
          <ListItemIcon>
            <Avatar src={user} alt={user} />
          </ListItemIcon>
          <ListItemText primary={`${user ? `${user.split(' ')[0]} ` : externalCreditorId}`} />
          <S.EndIcon>
            {anchorEl ? (
              <KeyboardArrowUpIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
          </S.EndIcon>
        </S.WrapperUser>
      </ListItem>

      <S.StyledMenu
        id="customized-menu"
        keepMounted
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!shouldHideLogout && (
          <S.ItemDropDown onClick={() => history.push(ROUTES.LOGOUT)}>
            <LogoutIcon fontSize="small" />
            <S.TextDropDown>{i18next.t('common.exit')}</S.TextDropDown>
          </S.ItemDropDown>
        )}
      </S.StyledMenu>

      {!shouldHideLogo && (
        <ListItem>
          <ListItemIcon>
            <LogoSplitc />
          </ListItemIcon>
          {isOpened && (
            <S.TextSplitc href="mailto:suporte@splitc.com.br">
              <ListItemText
                primary="suporte@splitc.com.br"
                secondary={
                  import.meta.env?.VITE_COMMIT &&
                  `${i18next.t('common.version')}: ${import.meta.env.VITE_COMMIT}`
                }
              />
            </S.TextSplitc>
          )}
        </ListItem>
      )}
    </Box>
  );
};

export default Footer;
