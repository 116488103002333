import { useGetPolicyAgreementsToSign } from 'service/document_signature';

export const useLockedByAgreement = (): { lockedByAgreement: boolean } => {
  const { data: documentAgreements } = useGetPolicyAgreementsToSign();

  return {
    lockedByAgreement: !!documentAgreements?.data.length
      ? documentAgreements?.data?.findIndex(agreement => agreement.lock_application) !== -1
      : false,
  };
};
