import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import useSelector from 'modules/default-selector';
import { hideError } from 'modules/ui';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.min.css';
import { ExpandLess, ExpandMore, FilterNone } from '@material-ui/icons';
import { showInfoToast } from 'utils/toast';

const StickTop = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
`;
const WhiteBackground = styled.div`
  position: absolute;
  background-color: #fff;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
`;

type ContentProps = { body: string | undefined | string[]; collapsed?: boolean };

const ErrorModalContent = ({ body, collapsed = true }: ContentProps) => {
  if (!body) return <></>;
  if (typeof body === 'string') {
    return <>{collapsed ? <pre>{body}</pre> : body}</>;
  }

  return (
    <>
      {body.map(e => (
        <>
          <span>{e}</span>
          <br />
        </>
      ))}
    </>
  );
};

export const copyCorrelationId = (correlationId: string | undefined) => {
  if (correlationId) {
    navigator.clipboard.writeText(correlationId);
    showInfoToast('Correlation id copiado para a área de transferência.');
  }
};

const UIModule = () => {
  const ui = useSelector(store => store.ui);
  const dispatch = useDispatch();
  const { splitcPalette } = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const closeModal = () => {
    setOpen(false);
    if (ui.error.onClose) ui.error.onClose();
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={2200}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {ui.loading && (
        <StickTop>
          <LinearProgress variant="indeterminate" />
        </StickTop>
      )}
      {ui.isFullPageLoading && (
        <WhiteBackground>
          <StickTop>
            <LinearProgress variant="indeterminate" />
          </StickTop>
        </WhiteBackground>
      )}
      <Dialog
        open={ui.error.status}
        onClose={() => {
          dispatch(hideError());
          closeModal();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            background: splitcPalette.neutral[10],
          }}
        >
          <span>{ui.error.heading}</span>
          <span> </span>
        </DialogTitle>
        <DialogContent
          style={{
            background: splitcPalette.neutral[10],
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {ui.error.collapseBody ? (
              <>
                <Button
                  onClick={handleClick}
                  style={{
                    fontSize: '1em',
                    textAlign: 'left',
                    fontWeight: 400,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  fullWidth
                  color="inherit"
                >
                  <ErrorModalContent body={ui.error.body} collapsed={false} />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </Button>
                <Collapse in={open} timeout="auto" unmountOnExit style={{ margin: 'auto' }}>
                  <Grid container justifyContent="center">
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: '10px',
                        background: splitcPalette.neutral[9],
                        padding: '8px',
                        borderRadius: '8px',
                        overflow: 'auto',
                        maxHeight: '400px',
                      }}
                    >
                      <ErrorModalContent body={ui.error.collapseBody} />
                      {ui.error.collapseJsonData && (
                        <>
                          :<br />
                          <Grid container justifyContent="center">
                            <Grid item xs={11} style={{ marginTop: '10px' }}>
                              <ErrorModalContent body={ui.error.collapseJsonData} />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Collapse>
              </>
            ) : (
              <ErrorModalContent body={ui.error.body} collapsed={false} />
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{
            background: splitcPalette.neutral[10],
            justifyContent: 'space-between',
          }}
        >
          {ui.error.correlationId && (
            <Typography
              component="span"
              variant="overline"
              onClick={() => copyCorrelationId(ui.error.correlationId)}
              style={{
                marginLeft: '16px',
                fontSize: '0.6rem',
                color: splitcPalette.neutral[5],
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {ui.error.correlationId}
              <FilterNone fontSize="small" style={{ marginLeft: 4, fontSize: '1rem' }} />
            </Typography>
          )}
          <Button
            onClick={() => {
              dispatch(hideError());
              closeModal();
            }}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UIModule;
