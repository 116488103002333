import { useTheme } from '@material-ui/core';
import { FC } from 'react';
import { iconBorderColor, iconFillColor } from 'theme';
import { DefaultIconType } from './types';

export const IconAdjustment: FC<DefaultIconType> = ({
  width = 24,
  height = 24,
  active = false,
}) => {
  const { splitcPalette, palette } = useTheme();
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM6 12v8M6 4v4-4ZM12 18v2m0-16v10V4Z"
        stroke={iconBorderColor(splitcPalette, active)}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        stroke={iconFillColor(palette, splitcPalette, active)}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM18 9v11m0-16v1-1Z"
        stroke={iconFillColor(palette, splitcPalette, active)}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
