import { useTheme } from '@material-ui/core';
import { FC } from 'react';
import { iconBorderColor } from '../../theme';
import { DefaultIconType } from './types';

export const ExternalLinkIcon: FC<DefaultIconType> = ({
  width = 18,
  height = 18,
  active = false,
  color,
}) => {
  const { splitcPalette } = useTheme();
  return (
    <svg
      viewBox="0 0 18 18"
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.17185 4.22854L11.7715 4.22854L11.7715 10.8282M4.22904 11.771L11.7715 4.22854L4.22904 11.771Z"
        stroke={iconBorderColor(splitcPalette, active, color)}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
