import { TooltipProps as MuiTooltipProps, Tooltip, makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ReactNode } from 'react';
import { FullColors } from 'theme/tokens';

export type TooltipProps = MuiTooltipProps & {
  children: JSX.Element;
  title: string | NonNullable<ReactNode>;
  big?: boolean;
  visible?: boolean;
  tooltipPlacementTop?: CSSProperties;
  neutralColorVariant?: keyof FullColors;
  maxWidth?: string;
};

export const CustomTooltip = ({
  children,
  title,
  big,
  tooltipPlacementTop = {},
  arrow = true,
  visible = true,
  maxWidth = '25rem',
  neutralColorVariant = 2,
  ...rest
}: TooltipProps) => {
  const tooltipClasses = makeStyles(theme => ({
    arrow: {
      color: theme.splitcPalette.neutral[neutralColorVariant],
    },
    tooltip: {
      color: theme.palette.getContrastText(theme.splitcPalette.neutral[neutralColorVariant]),
      fontSize: big ? '1rem' : '0.75rem',
      maxWidth,
      backgroundColor: theme.splitcPalette.neutral[neutralColorVariant],
    },
    tooltipPlacementTop,
  }));

  if (!visible) return children;

  return (
    <Tooltip
      classes={tooltipClasses()}
      arrow={arrow}
      title={title}
      PopperProps={{
        popperOptions: {
          positionFixed: true,
        },
      }}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};
