import Configuration from 'configuration/configuration';
import { Component, ErrorInfo, ReactNode, useSyncExternalStore } from 'react';
import { errorStore } from 'stores/errorStore';
import { logError } from 'utils/error';

interface ErrorBoundaryProps {
  children: ReactNode;
  fallback: ReactNode;
}

interface ClassErrorBoundaryProps extends ErrorBoundaryProps {
  error: unknown;
}

const isProduction = !Configuration.isTestEnv;

class ClassErrorBoundary extends Component<ClassErrorBoundaryProps> {
  static getDerivedStateFromError(error: unknown) {
    // getDerivedStateFromError only contains the error itself
    // and no other information, but can change component state
    // before rendering. If we need to log stacktrace and other
    // error info we should also use ComponentDidCatch(err, info).
    errorStore.errorSink(error);
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logError(error, errorInfo);
  }

  render() {
    const { fallback, error, children } = this.props;
    if (error && isProduction) return <>{fallback}</>;

    return <>{children}</>;
  }
}

const ErrorBoundary = ({ children, fallback }: ErrorBoundaryProps) => {
  const error = useSyncExternalStore(errorStore.subscribe, errorStore.getError);

  return (
    <ClassErrorBoundary fallback={fallback} error={error}>
      {children}
    </ClassErrorBoundary>
  );
};

export default ErrorBoundary;
