import { useTheme } from '@material-ui/core';
import { FC } from 'react';
import { DefaultIconType } from './types';

export const ChangeOffice: FC<DefaultIconType> = ({ width = 22, height = 22 }) => {
  const { splitcPalette } = useTheme();
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2929 0.792893C17.6834 0.402369 18.3166 0.402369 18.7071 0.792893L21.7071 3.79289C22.0976 4.18342 22.0976 4.81658 21.7071 5.20711C21.3166 5.59763 20.6834 5.59763 20.2929 5.20711L19 3.91421V14.5C19 15.0523 18.5523 15.5 18 15.5H8C7.44772 15.5 7 15.0523 7 14.5C7 13.9477 7.44772 13.5 8 13.5H17V3.91421L15.7071 5.20711C15.3166 5.59763 14.6834 5.59763 14.2929 5.20711C13.9024 4.81658 13.9024 4.18342 14.2929 3.79289L17.2929 0.792893ZM3 9.5C3 8.94772 3.44772 8.5 4 8.5H14C14.5523 8.5 15 8.94772 15 9.5C15 10.0523 14.5523 10.5 14 10.5H5V18.0858L6.29289 16.7929C6.68342 16.4024 7.31658 16.4024 7.70711 16.7929C8.09763 17.1834 8.09763 17.8166 7.70711 18.2071L4.70711 21.2071C4.31658 21.5976 3.68342 21.5976 3.29289 21.2071L0.292893 18.2071C-0.0976311 17.8166 -0.0976311 17.1834 0.292893 16.7929C0.683417 16.4024 1.31658 16.4024 1.70711 16.7929L3 18.0858V9.5Z"
        fill={splitcPalette.neutral[1]}
      />
    </svg>
  );
};
