import i18n from 'i18n/config';
import { lazy } from 'react';
import { useHistory } from 'react-router';
import { useGetPolicyAgreementsToSign } from 'service/document_signature';
import { generateQueryParams } from 'utils/url';
import * as S from './styles';

const AgreementsToSign = lazy(() => import('../../pages/Agreements/AgreementsToSign'));

export const AgreementsBanner = ({ locked }) => {
  const { data: documentAgreements } = useGetPolicyAgreementsToSign();
  const history = useHistory();

  return documentAgreements?.data.length ? (
    <>
      <S.AgreementsHeader>
        {i18n.t('policy_agreements.header_banner.title')}
        <b>
          {i18n.t('policy_agreements.header_banner.title_bold', {
            count: documentAgreements.data.length,
          })}
        </b>
        {
          <S.Link
            to={
              locked
                ? {
                    pathname: '/',
                  }
                : {
                    search: generateQueryParams(history.location.search, {
                      viewAgreements: 'true',
                    }),
                  }
            }
          >
            {i18n.t('policy_agreements.header_banner.link')}
          </S.Link>
        }
      </S.AgreementsHeader>
      <AgreementsToSign />
    </>
  ) : null;
};
