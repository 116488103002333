import { ListItemIcon, Menu, useTheme } from '@material-ui/core';
import { useAnalytics } from 'analytics/hooks';
import { CustomTooltip } from 'components/CustomTooltip';
import useSelector from 'modules/default-selector';
import { useLockedByAgreement } from 'pages/Agreements/hooks/useLockedByAgreement';
import { HTMLAttributes, MouseEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Claim } from 'shared-types';
import EVENTS from '../../../analytics/events';
import { MenuItem } from './hooks';
import * as S from './styles';

type Props = {
  item: MenuItem;
  isOpened: boolean;
};

const isMenuVisible = (menu: MenuItem, claims: Claim[]) => {
  const isParent = menu.subItems && menu.subItems?.length > 0;
  if (isParent && menu.subItems) {
    const atLeastOneChildIsVisible = menu.subItems.some(subMenu => subMenu.visible(claims));
    if (!atLeastOneChildIsVisible) return false;
  }

  return menu.visible(claims);
};

const MenuItemComponent = ({ item, isOpened }: Props) => {
  const isExpansive = (item.subItems && item.subItems.length > 0) ?? false;
  const history = useHistory();
  const { splitcPalette } = useTheme();
  const claims = useSelector(state => state.user.claims) ?? [];
  const { track } = useAnalytics();
  const shown = isMenuVisible(item, claims);
  const isExternalLink = item.link?.startsWith('http');
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { lockedByAgreement } = useLockedByAgreement();

  const disabled = item.disabled || (!item.preventLock && lockedByAgreement);

  useEffect(() => {
    if (!isOpened) setAnchorEl(null);
  }, [isOpened]);

  const handleClick = (clickedMenu: MenuItem) => (event: MouseEvent) => {
    const visibleSubitems: MenuItem[] = [];
    clickedMenu.subItems?.forEach(submenu => {
      const visible = submenu.visible(claims);
      if (visible) visibleSubitems.push(submenu);
    });

    const parentMenuWith1VisibleChild = visibleSubitems.length === 1;
    if (parentMenuWith1VisibleChild) {
      const [onlyItem] = visibleSubitems;
      if (onlyItem.link) {
        history.push(onlyItem.link);
        return;
      }
    }

    setAnchorEl(event.currentTarget);
  };

  const trackMenuEvent = (menu: MenuItem) => track(EVENTS.MENU_CLICK, { title: menu.title });

  const handleClose = (currentItem: MenuItem) => {
    if (currentItem.disabled || (!currentItem.preventLock && lockedByAgreement)) return;
    if (currentItem.shouldTrack) trackMenuEvent(currentItem);
    setAnchorEl(null);
  };

  const onRouteIsActive = (path: string[]) => {
    const checkRoute = (pathString: string) => history.location.pathname === pathString;
    return path.filter(checkRoute).length > 0;
  };

  const isRouteActive = onRouteIsActive(item.routesActive);

  const WrapperItem = ({
    children,
    ...rest
  }: HTMLAttributes<HTMLDivElement | HTMLAnchorElement>) =>
    item.link ? (
      <S.StyledLink to={String(item.link)} {...rest}>
        {children}
      </S.StyledLink>
    ) : (
      <div onClick={item.onClick} {...rest}>
        {children}
      </div>
    );

  if (!shown) return <></>;

  if (!isExpansive) {
    return (
      <>
        {item.onClick && (
          <CustomTooltip
            visible={!isOpened}
            arrow={!isOpened}
            neutralColorVariant={8}
            placement="left"
            title={item.title}
          >
            <WrapperItem>
              <S.CustomListItem disabled={disabled} selected={isRouteActive} button>
                <ListItemIcon>{item.icon({ active: isRouteActive })}</ListItemIcon>
                <S.CustomListText primary={item.title} />
              </S.CustomListItem>
            </WrapperItem>
          </CustomTooltip>
        )}
        {!item.onClick && isExternalLink && (
          <CustomTooltip
            neutralColorVariant={8}
            placement="left"
            visible={!isOpened}
            arrow={!isOpened}
            title={item.title}
          >
            <S.RawLink
              data-testid={`menu-item-${item.title}`}
              href={item.link}
              rel="noreferrer"
              target="_blank"
              onClick={() => handleClose(item)}
            >
              <S.CustomListItem disabled={disabled} selected={isRouteActive} button>
                <ListItemIcon>{item.icon({ active: isRouteActive })}</ListItemIcon>
                <S.CustomListText primary={item.title} />
                {isOpened && <S.EndIcon>{item.endIcon?.({ active: isRouteActive })}</S.EndIcon>}
              </S.CustomListItem>
            </S.RawLink>
          </CustomTooltip>
        )}
        {!item.onClick && !isExternalLink && (
          <CustomTooltip
            visible={!isOpened}
            arrow={!isOpened}
            neutralColorVariant={8}
            placement="left"
            title={item.title}
          >
            <S.StyledLink to={String(item.link)}>
              <S.CustomListItem disabled={disabled} selected={isRouteActive} button>
                <ListItemIcon>{item.icon({ active: isRouteActive })}</ListItemIcon>
                <S.CustomListText primary={item.title} />
              </S.CustomListItem>
            </S.StyledLink>
          </CustomTooltip>
        )}
      </>
    );
  }

  return (
    <>
      <CustomTooltip
        visible={!isOpened}
        arrow={!isOpened}
        neutralColorVariant={8}
        placement="left"
        title={item.title}
      >
        <S.CustomListItem
          button
          disabled={disabled}
          selected={isRouteActive}
          aria-owns={anchorEl ? item.title : undefined}
          aria-haspopup="true"
          onClick={handleClick(item)}
        >
          <ListItemIcon>{item.icon({ active: isRouteActive })}</ListItemIcon>
          <S.CustomListText primary={item.title} />
        </S.CustomListItem>
      </CustomTooltip>

      {!disabled && (
        <Menu
          id={item.title}
          onMouseLeave={event => event.preventDefault()}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => handleClose(item)}
          getContentAnchorEl={null}
          transitionDuration={0}
          PaperProps={{
            style: {
              margin: 0,
              padding: 10,
              backgroundColor: splitcPalette.neutral[11],
            },
          }}
          MenuListProps={{
            style: {
              padding: 0,
              margin: 0,
            },
          }}
        >
          <S.CustomMenuList id="list-item" onMouseOver={event => event.stopPropagation()}>
            {item.subItems?.map(subItem => {
              const isVisible = subItem.visible(claims);
              const isSubmenuActive = onRouteIsActive(subItem.routesActive);
              const isSubItemExternalLink = subItem.link?.startsWith('http');

              return (
                isVisible &&
                (isSubItemExternalLink ? (
                  <S.RawLink
                    key={subItem.title}
                    href={subItem.link}
                    rel="noreferrer"
                    target="_blank"
                    onClick={() => handleClose(subItem)}
                  >
                    <S.CustomListItem selected={isSubmenuActive} button>
                      <ListItemIcon>{subItem.icon({ active: isSubmenuActive })}</ListItemIcon>
                      <S.CustomListText primary={subItem.title} />
                    </S.CustomListItem>
                  </S.RawLink>
                ) : (
                  <CustomTooltip
                    key={subItem.title}
                    placement="right"
                    title={subItem.tooltipTitle ?? ''}
                  >
                    <S.StyledLink
                      to={String(subItem.link)}
                      as={subItem.disabled ? 'div' : Link}
                      onClick={() => handleClose(subItem)}
                    >
                      <S.CustomListItem
                        selected={isSubmenuActive}
                        disabled={subItem.disabled}
                        button
                      >
                        <ListItemIcon>{subItem.icon({ active: isSubmenuActive })}</ListItemIcon>
                        <S.CustomListText primary={subItem.title} />
                      </S.CustomListItem>
                    </S.StyledLink>
                  </CustomTooltip>
                ))
              );
            })}
          </S.CustomMenuList>
        </Menu>
      )}
    </>
  );
};

export default MenuItemComponent;
