import { useTheme } from '@material-ui/core';
import { FC } from 'react';
import { iconBorderColor, iconFillColor } from 'theme';
import { DefaultIconType } from './types';

export const SyncIcon: FC<DefaultIconType> = ({
  width = 24,
  height = 24,
  active = false,
  color = null,
}) => {
  const { splitcPalette, palette } = useTheme();
  return (
    <svg
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.38 15.51h4.53a1 1 0 0 1 .97.99V21a1 1 0 0 1-2 0v-1.77A10 10 0 0 1 2 12a1 1 0 1 1 2 0 8 8 0 0 0 13.78 5.51h-2.4a1 1 0 0 1 0-2Z"
        fill={color || iconBorderColor(splitcPalette, active)}
      />
      <path
        d="M12 2a10 10 0 0 0-6.88 2.77V3a1 1 0 0 0-2 0v4.5a1 1 0 0 0 1 1h4.5a1 1 0 0 0 0-2h-2.4A8 8 0 0 1 20 12a1 1 0 0 0 2 0A10 10 0 0 0 12 2Z"
        fill={color || iconFillColor(palette, splitcPalette, active)}
      />
    </svg>
  );
};
