import { useTheme } from '@material-ui/core';
import { FC } from 'react';
import { iconBorderColor, iconFillColor } from 'theme';
import { DefaultIconType } from './types';

export const CustomReportIcon: FC<DefaultIconType> = ({
  width = 24,
  height = 24,
  active = false,
}) => {
  const { splitcPalette, palette } = useTheme();
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 20h-1v-4a1 1 0 0 0-2 0v4h-2v-2a1 1 0 0 0-2 0v2h-2v-1a1 1 0 0 0-2 0v1H8v-2a1 1 0 1 0-2 0v2H4V3a1 1 0 0 0-2 0v18a1 1 0 0 0 1 1h18a1 1 0 0 0 0-2Z"
        stroke={iconBorderColor(splitcPalette, active)}
      />
      <path
        d="m8.524 11.378-.686 2.96a.744.744 0 0 0 .722.904.775.775 0 0 0 .155 0l2.98-.686 5.72-5.699-3.193-3.185-5.698 5.706ZM19.213 6.004l-2.13-2.13a.748.748 0 0 0-1.055 0l-1.185 1.184 3.189 3.189 1.184-1.185a.747.747 0 0 0-.003-1.058Z"
        stroke={iconFillColor(palette, splitcPalette, active)}
      />
    </svg>
  );
};
