import { useTheme } from '@material-ui/core';
import { FC } from 'react';
import { iconBorderColor, iconFillColor } from 'theme';

type Props = {
  width?: number;
  height?: number;
  active?: boolean;
};
export const ManagerReportIcon: FC<Props> = ({ width = 24, height = 24, active = false }) => {
  const { splitcPalette, palette } = useTheme();
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 20h-1v-4a1 1 0 0 0-2 0v4h-2v-2a1 1 0 0 0-2 0v2h-2v-1a1 1 0 0 0-2 0v1H8v-2a1 1 0 1 0-2 0v2H4V3a1 1 0 0 0-2 0v18a1 1 0 0 0 1 1h18a1 1 0 0 0 0-2Z"
        fill={iconBorderColor(splitcPalette, active)}
      />
      <path
        d="m18.558 7.907-1.229-.409.579-1.157a.65.65 0 0 0-.123-.741L16.4 4.215a.65.65 0 0 0-.747-.123l-1.158.579-.409-1.229A.65.65 0 0 0 13.475 3h-1.95a.65.65 0 0 0-.617.442l-.41 1.228-1.157-.578a.65.65 0 0 0-.741.123L7.215 5.6a.65.65 0 0 0-.123.748l.579 1.157-1.229.409A.65.65 0 0 0 6 8.525v1.95a.65.65 0 0 0 .442.617l1.228.41-.578 1.157a.65.65 0 0 0 .123.741L8.6 14.784a.65.65 0 0 0 .748.124l1.156-.579.41 1.229a.65.65 0 0 0 .617.442h1.95a.65.65 0 0 0 .618-.442l.41-1.229 1.156.579a.65.65 0 0 0 .735-.123l1.385-1.385a.65.65 0 0 0 .123-.748l-.579-1.156 1.229-.41a.65.65 0 0 0 .442-.611v-1.95a.65.65 0 0 0-.442-.618Zm-.858 2.1-.78.26a1.301 1.301 0 0 0-.754 1.833l.37.741-.715.715-.721-.39a1.302 1.302 0 0 0-1.813.754l-.26.78h-1.034l-.26-.78a1.301 1.301 0 0 0-1.833-.754l-.741.37-.715-.714.39-.722a1.3 1.3 0 0 0-.754-1.833l-.78-.26V8.993l.78-.26A1.3 1.3 0 0 0 8.834 6.9l-.37-.722.714-.715.722.371a1.3 1.3 0 0 0 1.833-.754l.26-.78h1.014l.26.78a1.3 1.3 0 0 0 1.833.754l.741-.37.715.714-.39.722a1.302 1.302 0 0 0 .754 1.813l.78.26v1.034Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
      <path
        d="m13.323 11.486-.249-.6.249.6a2.15 2.15 0 1 0-1.647-3.974 2.15 2.15 0 0 0 1.647 3.974Z"
        stroke={iconFillColor(palette, splitcPalette, active)}
        strokeWidth={1.3}
      />
    </svg>
  );
};
