import * as S from './styles';

export default function Loading() {
  return (
    <S.Container>
      <S.LoaderWrapper>
        <S.Circle />
        <S.Loader />
      </S.LoaderWrapper>
    </S.Container>
  );
}
